import { ReactElement } from 'react';
import EditPeriodRow from './EditPeriodRow';
import { Task, Period, LineItemType } from './types';
import { v4 as uuidv4 } from 'uuid';
import { getFollowingPeriodIndex } from './util';

interface Props {
  mainList: Array<Task | Period>;
  setMainList: (list: Array<Task | Period>) => void;
}

function EditPeriodView(props: Props): ReactElement {
  const { mainList, setMainList } = props;

  const periods = mainList
    .filter((item) => item.type === LineItemType.PERIOD)
    .map((period) => period as Period);

  const addPeriod = (index: number, name: string) => {
    const mainListIndex = mainList.indexOf(periods[index]);
    const updatedData = [...mainList];
    const newIndex = getFollowingPeriodIndex(mainListIndex, mainList);
    updatedData.splice(newIndex, 0, {
      id: uuidv4(),
      type: LineItemType.PERIOD,
      name,
    });
    setMainList(updatedData);
  };

  const editPeriod = (index: number, name: string) => {
    const updatedItems = [...mainList];
    const mainListIndex = mainList.indexOf(periods[index]);
    (updatedItems[mainListIndex] as Period).name = name;
    setMainList(updatedItems);
  };

  const deletePeriod = (index: number) => {
    const mainListIndex = mainList.indexOf(periods[index]);
    const updatedItems = [...mainList];
    updatedItems.splice(mainListIndex, 1);
    setMainList(updatedItems);
  };

  return (
    <>
      {periods.map((period, index) => (
        <EditPeriodRow
          key={period.id}
          period={period}
          addPeriod={(name) => addPeriod(index, name)}
          editPeriod={(name) => editPeriod(index, name)}
          deletePeriod={() => deletePeriod(index)}
          enableDelete={index > 0}
        />
      ))}
    </>
  );
}

export default EditPeriodView;
