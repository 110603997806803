import { Box, Divider } from '@mui/material';
import { ReactElement } from 'react';
import { Period } from './types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface Props {
  period: Period;
}

function PeriodRowInner(props: Props): ReactElement {
  const { period } = props;

  const nameStyle: React.CSSProperties = {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '11px',
    textAlign: 'center',
    color: '#016906',
  };

  return (
    <>
      <Box
        style={{ display: 'flex', alignItems: 'center', marginRight: '28px' }}
      >
        <ChevronRightIcon style={{ color: '#016906' }} />
        <Divider
          style={{
            border: 'solid 1px #016906',
            width: '100%',
            margin: '0 -10px',
          }}
        />
        <ChevronLeftIcon style={{ color: '#016906' }} />
      </Box>
      <Box
        style={{
          ...nameStyle,
          marginTop: '-20px',
          position: 'relative',
        }}
      >
        <Box
          style={{
            display: 'inline-block',
            backgroundColor: 'white',
            padding: '0 4px',
          }}
        >
          {period.name}
        </Box>
      </Box>
    </>
  );
}

export default PeriodRowInner;
