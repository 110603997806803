import { Box, Checkbox } from '@mui/material';
import { ReactElement, useState } from 'react';
import BackdateButton from './BackdateButton';
import { Task } from './types';
import { isMobile } from 'react-device-detect';

interface Props {
  task: Task;
  markUndone: () => void;
  backdate: (date: Date) => void;
}

function CompletedTaskRow(props: Props): ReactElement {
  const { task, markUndone, backdate } = props;

  const [hovering, setHovering] = useState(false);

  const setNewDate = (date: Date | null) => {
    if (
      !date ||
      !task.completed ||
      date.toDateString() === task.completed.toDateString()
    ) {
      return;
    }
    backdate(date);
  };

  return (
    <div
      style={{
        display: 'flex',
        margin: '0 16px 0 18px',
        alignItems: 'center',
      }}
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
    >
      <Box
        sx={{
          margin: '0 4px',
          padding: '0 7px',
          width: '12px',
          backgroundColor: '#016906',
          alignSelf: 'stretch',
        }}
      >
        &nbsp;
      </Box>
      <Checkbox
        style={{ opacity: 0.7 }}
        checked={!!task.completed}
        onChange={() => markUndone()}
      />
      <div style={{ opacity: 0.7, textDecoration: 'line-through', flex: 1 }}>
        {task.text}
      </div>
      <div
        style={{
          opacity: hovering || isMobile ? 1 : 0,
          transition: 'opacity 0.2s',
        }}
      >
        <BackdateButton task={task} setNewDate={setNewDate} />
      </div>
    </div>
  );
}

export default CompletedTaskRow;
