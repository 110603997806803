import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateArchivedList } from './api';
import { Task, Period } from './types';

export function useArchivedListMutation() {
  const queryClient = useQueryClient();

  return useMutation(updateArchivedList, {
    onMutate: (newArchiveList: Array<Task | Period>) => {
      queryClient.cancelQueries(['archived']);
      queryClient.setQueryData(['archived'], (old) => newArchiveList);
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['archived']);
    },
  });
}
