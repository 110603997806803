import { ReactElement } from 'react';
import { Period } from './types';

import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import AddTaskRow from './AddTaskRow';
import PeriodRowInner from './PeriodRowInner';

interface Props {
  period: Period;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}

function PeriodRow(props: Props): ReactElement {
  const { period, provided, snapshot } = props;

  const ENABLE_ADD_ROW = false;

  return (
    <>
      {ENABLE_ADD_ROW && <AddTaskRow isDragging={snapshot.isDragging} />}
      <div {...provided.dragHandleProps}>
        <PeriodRowInner period={period} />
      </div>
    </>
  );
}

export default PeriodRow;
