import { Task, Period, LineItemType } from './types';
import { v4 as uuidv4 } from 'uuid';

export const defaultLastCronExecution = new Date(2016, 5, 3);

export const defaultMainList: Array<Task | Period> = [
  {
    id: uuidv4(),
    type: LineItemType.PERIOD,
    name: 'Today',
  },
  {
    id: uuidv4(),
    type: LineItemType.TASK,
    text: 'do stretch session',
    created: new Date(),
    completed: undefined,
    completedTzOffset: undefined,
  },
  {
    id: uuidv4(),
    type: LineItemType.TASK,
    text: 'cook dinner',
    created: new Date(),
    completed: undefined,
    completedTzOffset: undefined,
  },
  {
    id: uuidv4(),
    type: LineItemType.PERIOD,
    name: 'This week',
  },
  {
    id: uuidv4(),
    type: LineItemType.TASK,
    text: 'call up bff',
    created: new Date(),
    completed: undefined,
    completedTzOffset: undefined,
  },
  {
    id: uuidv4(),
    type: LineItemType.TASK,
    text: 'pretend to file tps report',
    created: new Date(),
    completed: undefined,
    completedTzOffset: undefined,
  },
  {
    id: uuidv4(),
    type: LineItemType.PERIOD,
    name: 'Later',
  },
  {
    id: uuidv4(),
    type: LineItemType.TASK,
    text: 'clean out fridge',
    created: new Date(),
    completed: undefined,
    completedTzOffset: undefined,
  },
  {
    id: uuidv4(),
    type: LineItemType.TASK,
    text: 'get bike serviced',
    created: new Date(),
    completed: undefined,
    completedTzOffset: undefined,
  },
];

export const defaultArchivedList: Array<Task | Period> = [];
