import { ReactElement, useCallback, useEffect, useState } from 'react';
import { getToday } from './util';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { LineItemType, Period, Task } from './types';
import startOfDay from 'date-fns/startOfDay';
import isEqual from 'date-fns/isEqual';
import ArchivedTaskRow from './ArchivedTaskRow';
import { IconButton } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  fullScreen: boolean;
  close: () => void;
  archivedTasks: Array<Task | Period>;
}

function ArchiveView(props: Props): ReactElement {
  const { fullScreen, close, archivedTasks } = props;
  const [viewingDate, setViewingDate] = useState(subDays(getToday(), 1));

  const getViewingTasksForDate = useCallback(
    () =>
      archivedTasks.filter(
        (task) =>
          task.type === LineItemType.TASK &&
          task.completed &&
          isEqual(startOfDay(task.completed), viewingDate)
      ) as Array<Task>,
    [archivedTasks, viewingDate]
  );

  const [viewingTasks, setViewingTasks] = useState<Array<Task>>(
    getViewingTasksForDate()
  );

  useEffect(() => {
    setViewingTasks(getViewingTasksForDate());
  }, [getViewingTasksForDate, viewingDate]);

  return (
    <>
      {fullScreen && (
        <div
          style={{
            margin: '12px 24px -8px 32px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ fontWeight: 'bold' }}>Archive</div>
          <IconButton aria-label="close" onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <div
        style={{
          padding: '24px 32px 32px',
          width: fullScreen ? '100%' : '400px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <IconButton
            aria-label="previous"
            onClick={() => setViewingDate(subDays(viewingDate, 1))}
            color="primary"
            style={{ marginLeft: '-8px' }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <div style={{ fontWeight: 'bold' }}>
            {format(viewingDate, 'EEEE, MMMM do')}
          </div>
          <IconButton
            aria-label="next"
            onClick={() => setViewingDate(addDays(viewingDate, 1))}
            color="primary"
            disabled={isEqual(viewingDate, subDays(getToday(), 1))}
            style={{ marginRight: '-8px' }}
          >
            <NavigateNextIcon />
          </IconButton>
        </div>
        {viewingTasks.map((task) => (
          <ArchivedTaskRow key={task.id} task={task} />
        ))}
        {viewingTasks.length === 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <div style={{ color: '#888', fontWeight: 700 }}>No items</div>
            <div style={{ marginTop: '8px', color: '#555' }}>
              Perhaps nothing was done. Or perhaps it was a brief respite from
              the heavy burden of feeling like one must be a productive member
              of society.
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ArchiveView;
