import { ReactElement, useState } from 'react';
import { Period } from './types';
import EditIcon from '@mui/icons-material/Edit';
import { Button, FormHelperText, IconButton, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
  period: Period;
  addPeriod: (name: string) => void;
  editPeriod: (name: string) => void;
  deletePeriod: () => void;
  enableDelete: boolean;
}

function EditPeriodRow(props: Props): ReactElement {
  const { period, addPeriod, editPeriod, deletePeriod, enableDelete } = props;

  const [name, setName] = useState(period.name);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [addingName, setAddingName] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!isEditing && (
          <>
            <h4>{name}</h4>
            {!isDeleting && (
              <>
                <IconButton
                  aria-label="edit"
                  onClick={() => setIsEditing(true)}
                >
                  <EditIcon />
                </IconButton>
                {enableDelete && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => setIsDeleting(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </>
            )}
            {isDeleting && (
              <>
                <Button
                  aria-label="cancel"
                  onClick={() => setIsDeleting(false)}
                >
                  Cancel
                </Button>
                <Button color="error" onClick={deletePeriod}>
                  Delete
                </Button>
              </>
            )}
          </>
        )}
        {isEditing && (
          <>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
            />
            <IconButton
              aria-label="save"
              disabled={name === period.name || !name.trim()}
              onClick={() => {
                editPeriod(name.trim());
                setIsEditing(false);
              }}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              aria-label="cancel"
              onClick={() => {
                setName(period.name);
                setIsEditing(false);
              }}
            >
              <ClearIcon />
            </IconButton>
          </>
        )}
      </div>
      {isDeleting && (
        <FormHelperText style={{ textAlign: 'center' }} color="error">
          Deleting this period will move all its tasks and recurring tasks into
          the previous period
        </FormHelperText>
      )}
      {!isAdding && (
        <Button
          variant="outlined"
          startIcon={<AddCircleIcon />}
          onClick={() => setIsAdding(true)}
        >
          Insert new period
        </Button>
      )}
      {isAdding && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextField
            value={addingName}
            onChange={(e) => setAddingName(e.target.value)}
            autoComplete="off"
          />
          <IconButton
            aria-label="save"
            disabled={!addingName.trim()}
            onClick={() => {
              addPeriod(addingName.trim());
              setAddingName('');
              setIsAdding(false);
            }}
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            aria-label="cancel"
            onClick={() => {
              setAddingName('');
              setIsAdding(false);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default EditPeriodRow;
