import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { getArchivedList, getMainList } from './api';
import { useArchivedListMutation } from './editArchivedList';
import { useMainListMutation } from './editMainList';
import MainListView from './MainListView';
import { Period, Task } from './types';

function MainListViewContainer(): ReactElement {
  const { data: mainList, isLoading: mainLoading } = useQuery<
    Array<Task | Period>
  >(['todos'], getMainList);
  const { mutate: setMainList } = useMainListMutation();

  const { data: archivedList, isLoading: archivedLoading } = useQuery<
    Array<Task | Period>
  >(['archived'], getArchivedList);

  const { mutate: setArchivedList } = useArchivedListMutation();

  if (mainLoading || !mainList || archivedLoading || !archivedList) {
    return <>Loading</>;
  }

  return (
    <MainListView
      mainList={mainList}
      setMainList={setMainList}
      archivedList={archivedList}
      setArchivedList={setArchivedList}
    />
  );
}

export default MainListViewContainer;
