import { ReactElement } from 'react';

interface Props {
  isDragging: boolean;
}

function AddTaskRow(props: Props): ReactElement | null {
  const { isDragging } = props;

  const getStyle = () => ({
    opacity: isDragging ? '0' : '1',
    transition: 'opacity 0.2s',
  });

  return <div style={getStyle()}>ADD ROW</div>;
}

export default AddTaskRow;
