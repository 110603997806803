import { Box, Dialog, DialogTitle, IconButton, Tab, Tabs } from '@mui/material';
import { ReactElement, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Period, Task } from './types';
import { getMainList } from './api';
import { useQuery } from '@tanstack/react-query';
import EditPeriodView from './EditPeriodView';
import CloseIcon from '@mui/icons-material/Close';
import { useMainListMutation } from './editMainList';

function SettingsButton(): ReactElement | null {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, isLoading } = useQuery<Array<Task | Period>>(
    ['todos'],
    getMainList
  );
  const { mutate: setMainList } = useMainListMutation();

  if (isLoading || !data) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-label="settings"
        onClick={() => setShowDialog(true)}
        style={{ color: 'white' }}
      >
        <SettingsIcon />
      </IconButton>
      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        fullScreen={fullScreen}
      >
        <div
          style={{
            width: fullScreen ? '100%' : '600px',
            height: fullScreen ? '100%' : '600px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <DialogTitle>Settings</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setShowDialog(false)}
              style={{ marginRight: '16px' }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={selectedTab}
              onChange={(e, v) => setSelectedTab(v)}
              centered={!fullScreen}
              variant="fullWidth"
            >
              <Tab label="Periods" />
              <Tab label="Recurring Tasks" />
            </Tabs>
          </Box>
          <div
            style={{
              padding: '12px 24px',
            }}
          >
            {selectedTab === 0 && (
              <EditPeriodView mainList={data} setMainList={setMainList} />
            )}
            {selectedTab === 1 && <>Coming later yo</>}
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default SettingsButton;
