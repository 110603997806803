import startOfDay from 'date-fns/startOfDay';
import { Task, Period, LineItemType } from './types';

export const listParser = (str: string) => {
  const initialParse: Array<Task | Period> = JSON.parse(str);
  return initialParse.map((item) => {
    if (item.type === LineItemType.TASK && !!item.completed) {
      item.completed = new Date(item.completed);
    }
    if (item.type === LineItemType.TASK && !!item.reminder) {
      item.reminder = new Date(item.reminder);
    }
    return item;
  });
};

export const getToday = (): Date => {
  return startOfDay(new Date());
};

export const getPrecedingPeriodIndex = (
  index: number,
  list: Array<Task | Period>
) => {
  while (index > 0) {
    index--;
    if (list[index].type === LineItemType.PERIOD) {
      return index;
    }
  }
  return -1; // No preceding period.
};

export const getFollowingPeriodIndex = (
  index: number,
  list: Array<Task | Period>
) => {
  while (index < list.length - 1) {
    index++;
    if (list[index].type === LineItemType.PERIOD) {
      return index;
    }
  }
  return list.length;
};
