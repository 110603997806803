import { createRef, ReactElement, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import PeriodRow from './PeriodRow';
import TaskRow from './TaskRow';

import { Task, Period, LineItemType } from './types';
import PeriodRowInner from './PeriodRowInner';
import CompletedTaskRow from './CompletedTaskRow';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import { getFollowingPeriodIndex, getPrecedingPeriodIndex } from './util';

interface Props {
  mainList: Array<Task | Period>;
  setMainList: (list: Array<Task | Period>) => void;
  archivedList: Array<Task | Period>;
  setArchivedList: (list: Array<Task | Period>) => void;
}

function MainListView(props: Props): ReactElement {
  const { mainList, setMainList, archivedList, setArchivedList } = props;

  const getInputRefs = () => {
    const refs: { [id: string]: React.RefObject<HTMLInputElement> } = {};
    mainList.forEach((item: Task | Period) => {
      if (item.type === LineItemType.TASK) {
        refs[item.id] = createRef<HTMLInputElement>();
      }
    });
    return refs;
  };

  const [inputRefs, setInputRefs] = useState(getInputRefs);

  const liveList = mainList.filter(
    (item, index) =>
      index > 0 && (item.type === LineItemType.PERIOD || !item.completed)
  );

  const completedList = mainList
    .filter((item) => item.type === LineItemType.TASK && item.completed)
    .map((item) => item as Task)
    .sort((a, b) => {
      if (a.completed === undefined || b.completed === undefined) {
        throw Error('Done item missing completed date.');
      }
      return a.completed > b.completed ? 1 : -1;
    });

  // Drag and drop functions.

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(mainList);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const sourceItem = liveList[result.source.index];
    if (sourceItem.type === LineItemType.PERIOD) {
      if (
        result.destination.index <=
        getPrecedingPeriodIndex(result.source.index, liveList)
      ) {
        return;
      }
      if (
        result.destination.index >=
        getFollowingPeriodIndex(result.source.index, liveList)
      ) {
        return;
      }
    }

    const mainListSourceIndex = mainList.indexOf(sourceItem);
    const mainLiListDestinationIndex = mainList.indexOf(
      liveList[result.destination.index]
    );
    const newItems = reorder(mainListSourceIndex, mainLiListDestinationIndex);
    setMainList(newItems);
  };

  const onBeforeDragStart = () => {
    Object.values(inputRefs).forEach((ref) => {
      ref.current && ref.current.blur();
    });
  };

  // Task update functions.

  const addTask = (index: number, followingLine: boolean) => {
    const updatedItems = [...mainList];
    const newTask: Task = {
      id: uuidv4(),
      type: LineItemType.TASK,
      text: '',
      created: new Date(),
      completed: undefined,
      completedTzOffset: undefined,
    };
    const mainListIndex = mainList.indexOf(liveList[index]);
    updatedItems.splice(
      followingLine ? mainListIndex + 1 : mainListIndex,
      0,
      newTask
    );
    setMainList(updatedItems);

    const updatedRefs = { ...inputRefs };
    const ref = createRef<HTMLInputElement>();
    updatedRefs[newTask.id] = ref;
    setInputRefs(updatedRefs);

    if (followingLine) {
      setTimeout(() => {
        if (!ref.current) {
          return;
        }
        ref.current.focus();
      }, 50);
    }
  };

  const editTask = (index: number, updatedText: string) => {
    const updatedItems = [...mainList];
    const mainListIndex = mainList.indexOf(liveList[index]);
    (updatedItems[mainListIndex] as Task).text = updatedText;
    setMainList(updatedItems);
  };

  const deleteTask = (index: number, focusPreceding: boolean) => {
    const updatedRefs = { ...inputRefs };
    delete updatedRefs[liveList[index].id];
    setInputRefs(updatedRefs);

    const updatedItems = [...mainList];
    // updatedItems[index - 1].content = updatedItems[index - 1].content + text;
    const mainListIndex = mainList.indexOf(liveList[index]);
    updatedItems.splice(mainListIndex, 1);
    setMainList(updatedItems);
    window.setTimeout(() => {
      focusTaskLine(index, focusPreceding);
    }, 50);
  };

  const getPrecedingTask = (index: number) => {
    while (index > 0) {
      index--;
      if (liveList[index].type === LineItemType.TASK) {
        return liveList[index];
      }
    }
    return null;
  };

  const getFollowingTask = (index: number) => {
    while (index < liveList.length - 1) {
      index++;
      if (liveList[index].type === LineItemType.TASK) {
        return liveList[index];
      }
    }
    return null;
  };

  const focusTaskLine = (index: number, focusPreceding: boolean) => {
    if (index < 0 || index >= liveList.length) {
      return;
    }
    const focusTask = focusPreceding
      ? getPrecedingTask(index)
      : getFollowingTask(index);
    if (!focusTask) {
      return;
    }
    const ref = inputRefs[focusTask.id];
    if (!ref.current) {
      return;
    }
    ref.current.focus();
    if (focusPreceding) {
      const endPos = (focusTask as Task).text.length;
      ref.current.setSelectionRange(endPos, endPos);
    } else {
      ref.current.setSelectionRange(0, 0);
    }
  };

  const markTaskDone = (index: number) => {
    const updatedItems = [...mainList];
    const mainListIndex = mainList.indexOf(liveList[index]);
    (updatedItems[mainListIndex] as Task).completed = new Date();
    setMainList(updatedItems);
  };

  const markTaskUndone = (index: number) => {
    const updatedItems = [...mainList];
    const mainListIndex = mainList.indexOf(completedList[index]);
    (updatedItems[mainListIndex] as Task).completed = undefined;
    setMainList(updatedItems);
  };

  const backdateCompletedTask = (index: number, date: Date) => {
    const newEndDate = endOfDay(date);
    const updatedTask = {
      ...completedList[index],
      completed: newEndDate,
    } as Task;
    setArchivedList([...archivedList, updatedTask]);
    const updatedItems = [...mainList];
    const mainListIndex = mainList.indexOf(completedList[index]);
    updatedItems.splice(mainListIndex, 1);
    setMainList(updatedItems);
  };

  const setReminderForTask = (index: number, date: Date | null) => {
    const reminderDate = date ? startOfDay(date) : undefined;
    const updatedItems = [...mainList];
    const mainListIndex = mainList.indexOf(liveList[index]);
    (updatedItems[mainListIndex] as Task).reminder = reminderDate;
    setMainList(updatedItems);
  };

  return (
    <>
      <PeriodRowInner period={mainList[0] as Period} />
      {completedList.map((doneItem, index) => (
        <CompletedTaskRow
          key={doneItem.id}
          task={doneItem}
          markUndone={() => markTaskUndone(index)}
          backdate={(date) => backdateCompletedTask(index, date)}
        />
      ))}
      <DragDropContext
        onDragEnd={onDragEnd}
        onBeforeDragStart={onBeforeDragStart}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              // style={getListStyle(snapshot.isDraggingOver)}
            >
              {liveList.map((item: Task | Period, index: number) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      {item.type === LineItemType.TASK && (
                        <TaskRow
                          task={item as Task}
                          provided={provided}
                          snapshot={snapshot}
                          updateText={(text) => editTask(index, text)}
                          addTask={(followingLine) =>
                            addTask(index, followingLine)
                          }
                          deleteTask={(focusPreceding) =>
                            deleteTask(index, focusPreceding)
                          }
                          markDone={() => markTaskDone(index)}
                          setReminder={(date: Date | null) =>
                            setReminderForTask(index, date)
                          }
                          focusTaskLine={(focusPreceding) =>
                            focusTaskLine(index, focusPreceding)
                          }
                          precedesPeriod={
                            index === liveList.length - 1 ||
                            liveList[index + 1].type === LineItemType.PERIOD
                          }
                          inputRef={inputRefs[item.id]}
                        />
                      )}
                      {item.type === LineItemType.PERIOD && (
                        <PeriodRow
                          period={item as Period}
                          provided={provided}
                          snapshot={snapshot}
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default MainListView;
