export enum LineItemType {
  TASK = 'task',
  PERIOD = 'period',
}

export interface LineItem {
  id: string;
  type: LineItemType;
}

export interface Task extends LineItem {
  type: LineItemType.TASK;
  text: string;
  created: Date;
  completed?: Date;
  completedTzOffset?: number;
  reminder?: Date;
}

export interface Period extends LineItem {
  type: LineItemType.PERIOD;
  name: string;
}
