import compareAsc from 'date-fns/compareAsc';
import useLocalStorage from 'use-local-storage';
import { defaultLastCronExecution } from './constants';
import { LineItemType, Period, Task } from './types';
import { getToday } from './util';
import useWindowFocus from 'use-window-focus';
import { ReactElement, useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getArchivedList, getMainList } from './api';
import { useMainListMutation } from './editMainList';
import { useArchivedListMutation } from './editArchivedList';

function Crons(): ReactElement | null {
  const { data: mainList, isLoading: mainLoading } = useQuery<
    Array<Task | Period>
  >(['todos'], getMainList);
  const { data: archivedList, isLoading: archivedLoading } = useQuery<
    Array<Task | Period>
  >(['archived'], getArchivedList);

  const { mutate: setMainList } = useMainListMutation();
  const { mutate: setArchivedList } = useArchivedListMutation();

  if (mainLoading || !mainList || archivedLoading || !archivedList) {
    return null;
  }

  return (
    <CronsInner
      mainList={mainList}
      setMainList={setMainList}
      archivedList={archivedList}
      setArchivedList={setArchivedList}
    />
  );
}

interface Props {
  mainList: Array<Task | Period>;
  setMainList: (list: Array<Task | Period>) => void;
  archivedList: Array<Task | Period>;
  setArchivedList: (list: Array<Task | Period>) => void;
}

function CronsInner(props: Props): null {
  const { mainList, setMainList, archivedList, setArchivedList } = props;

  const windowFocused = useWindowFocus();

  const [lastCronExecution, setLastCronExecution] = useLocalStorage(
    'lastCronExecution',
    defaultLastCronExecution,
    {
      parser: (str) => {
        const parsed = JSON.parse(str);
        return new Date(parsed);
      },
    }
  );

  const archive = useCallback(() => {
    const [toArchive, remainder] = mainList.reduce(
      (result, item) => {
        result[
          item.type === LineItemType.TASK &&
          item.completed &&
          compareAsc(item.completed, getToday()) < 0
            ? 0
            : 1
        ].push(item);
        return result;
      },
      [[] as Array<Task | Period>, [] as Array<Task | Period>]
    );

    setMainList(remainder);
    setArchivedList([...archivedList, ...toArchive]);
  }, [archivedList, mainList, setArchivedList, setMainList]);

  const maybeExecuteCrons = useCallback(() => {
    if (lastCronExecution && compareAsc(lastCronExecution, getToday()) >= 0) {
      return;
    }
    console.log('Running cron');
    archive();
    setLastCronExecution(getToday());
  }, [archive, lastCronExecution, setLastCronExecution]);

  useEffect(() => {
    if (windowFocused) {
      maybeExecuteCrons();
    }
  }, [maybeExecuteCrons, windowFocused]);

  return null;
}

export default Crons;
