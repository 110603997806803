import { Dialog, IconButton } from '@mui/material';
import { ReactElement, useState } from 'react';
import InventoryIcon from '@mui/icons-material/Inventory';
import ArchiveView from './ArchiveView';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Period, Task } from './types';
import { getArchivedList } from './api';
import { useQuery } from '@tanstack/react-query';

function ViewArchiveButton(): ReactElement | null {
  const [showDialog, setShowDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, isLoading } = useQuery<Array<Task | Period>>(
    ['archived'],
    getArchivedList
  );

  if (isLoading || !data) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-label="view archive"
        onClick={() => setShowDialog(true)}
        style={{ color: 'white' }}
      >
        <InventoryIcon />
      </IconButton>
      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        fullScreen={fullScreen}
      >
        <ArchiveView
          archivedTasks={data}
          fullScreen={fullScreen}
          close={() => setShowDialog(false)}
        />
      </Dialog>
    </>
  );
}

export default ViewArchiveButton;
