import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateMainList } from './api';
import { Task, Period } from './types';

export function useMainListMutation() {
  const queryClient = useQueryClient();

  return useMutation(updateMainList, {
    onMutate: (newMainList: Array<Task | Period>) => {
      queryClient.cancelQueries(['todos']);
      queryClient.setQueryData(['todos'], (old) => newMainList);
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['todos']);
    },
  });
}
