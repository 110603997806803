import './App.css';
import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import ViewArchiveButton from './ViewArchiveButton';
import MainListViewContainer from './MainListViewContainer';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import SettingsButton from './SettingsButton';

function App() {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AppBar position="relative" sx={{ marginBottom: '16px' }}>
          <Container maxWidth="sm">
            <Toolbar
              disableGutters
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography variant="h6" noWrap component="div">
                Finite
              </Typography>
              <div>
                <ViewArchiveButton />
                <SettingsButton />
              </div>
            </Toolbar>
          </Container>
        </AppBar>
        <Container maxWidth="sm">
          <MainListViewContainer />
        </Container>
      </LocalizationProvider>
    </div>
  );
}

export default App;
