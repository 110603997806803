import { Dialog, DialogTitle, IconButton, Button } from '@mui/material';
import { ReactElement, useState } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Task } from './types';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import CloseIcon from '@mui/icons-material/Close';
import isPast from 'date-fns/isPast';

interface Props {
  task: Task;
  setReminder: (date: Date | null) => void;
}

function ReminderButton(props: Props): ReactElement | null {
  const { task, setReminder } = props;

  const [showDialog, setShowDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const shouldRemind = !!task.reminder && isPast(task.reminder);

  return (
    <>
      <IconButton
        aria-label="set reminder"
        onClick={() => setShowDialog(true)}
        style={{ color: shouldRemind ? '#cc3d00' : 'lightgray' }}
      >
        {shouldRemind ? (
          <NotificationImportantIcon />
        ) : task.reminder ? (
          <NotificationsIcon />
        ) : (
          <NotificationsNoneIcon />
        )}
      </IconButton>
      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        fullScreen={fullScreen}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <DialogTitle>Set reminder</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setShowDialog(false)}
              style={{ marginRight: '16px' }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <CalendarPicker
            date={task.reminder ?? null}
            onChange={(date) => {
              setShowDialog(false);
              setReminder(date);
            }}
          />
          {!!task.reminder && (
            <Button
              onClick={() => {
                setShowDialog(false);
                setReminder(null);
              }}
            >
              Clear reminder
            </Button>
          )}
        </div>
      </Dialog>
    </>
  );
}

export default ReminderButton;
