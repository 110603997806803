import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { ReactElement, useState } from 'react';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Task } from './types';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  task: Task;
  setNewDate: (date: Date | null) => void;
}

function BackdateButton(props: Props): ReactElement | null {
  const { task, setNewDate } = props;

  const [showDialog, setShowDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <IconButton
        aria-label="backdate task"
        onClick={() => setShowDialog(true)}
        style={{ color: 'lightgray' }}
      >
        <EventRepeatIcon />
      </IconButton>
      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        fullScreen={fullScreen}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <DialogTitle>Backdate completion</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setShowDialog(false)}
              style={{ marginRight: '16px' }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <CalendarPicker
            date={task.completed ?? null}
            onChange={(date) => {
              setShowDialog(false);
              setNewDate(date);
            }}
            disableFuture
          />
        </div>
      </Dialog>
    </>
  );
}

export default BackdateButton;
