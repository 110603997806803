import { defaultArchivedList, defaultMainList } from './constants';
import { Task, Period } from './types';
import { listParser } from './util';

export const getMainList = async (): Promise<Array<Task | Period>> => {
  const unparsed = await localStorage.getItem('list');
  if (!unparsed) {
    return defaultMainList;
  }
  return listParser(unparsed);
};

export const updateMainList = async (list: Array<Task | Period>) => {
  return await localStorage.setItem('list', JSON.stringify(list));
};

export const getArchivedList = async (): Promise<Array<Task | Period>> => {
  const unparsed = await localStorage.getItem('archivedList');
  if (!unparsed) {
    return defaultArchivedList;
  }
  return listParser(unparsed);
};

export const updateArchivedList = async (list: Array<Task | Period>) => {
  return await localStorage.setItem('archivedList', JSON.stringify(list));
};
