import { ReactElement } from 'react';
import { Task } from './types';
import DoneIcon from '@mui/icons-material/Done';

interface Props {
  task: Task;
}

function ArchivedTaskRow(props: Props): ReactElement {
  const { task } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center', minHeight: '30px' }}>
      <DoneIcon color="primary" style={{ marginRight: '8px' }} />
      {task.text}
    </div>
  );
}

export default ArchivedTaskRow;
