import { ReactElement, useState } from 'react';
import { Task } from './types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ClearIcon from '@mui/icons-material/Clear';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { Checkbox, IconButton, TextField } from '@mui/material';
import { isMobile } from 'react-device-detect';
import ReminderButton from './ReminderButton';

interface Props {
  task: Task;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  updateText: (text: string) => void;
  addTask: (followingLine: boolean) => void;
  deleteTask: (focusPreceding: boolean) => void;
  markDone: () => void;
  setReminder: (date: Date | null) => void;
  focusTaskLine: (focusPreceding: boolean) => void;
  precedesPeriod: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
}

function TaskRow(props: Props): ReactElement {
  const {
    task,
    provided,
    snapshot,
    updateText,
    addTask,
    deleteTask,
    markDone,
    setReminder,
    focusTaskLine,
    precedesPeriod,
    inputRef,
  } = props;

  const [hovering, setHovering] = useState(false);

  const getStyle = (isDragging: boolean) => ({
    display: 'flex',
    marginRight: '16px',
    alignItems: 'center',
    backgroundColor: isDragging || precedesPeriod ? 'transparent' : 'white',
  });

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // TODO: Make invariant.
    if (!inputRef.current) {
      return;
    }
    if (e.key === 'Backspace' && inputRef.current.selectionStart === 0) {
      if (task.text.length === 0) {
        deleteTask(true);
      }
    } else if (
      e.key === 'ArrowDown' &&
      inputRef.current.selectionStart === task.text.length
    ) {
      e.preventDefault();
      focusTaskLine(false);
    } else if (e.key === 'ArrowUp' && inputRef.current.selectionStart === 0) {
      e.preventDefault();
      focusTaskLine(true);
    }
  };

  return (
    <div
      style={getStyle(snapshot.isDragging)}
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
    >
      <div style={{ paddingLeft: '16px' }} {...provided.dragHandleProps}>
        <DragIndicatorIcon
          style={{
            color: 'gray',
            paddingTop: '4px',
            opacity: hovering || snapshot.isDragging || isMobile ? 1 : 0,
            transition: 'opacity 0.2s',
          }}
        />
      </div>
      <Checkbox
        checked={!!task.completed}
        onChange={() => markDone()}
        disabled={!task.text.trim()}
      />
      <TextField
        variant="standard"
        multiline
        defaultValue={task.text}
        // Should be React.FormEvent<HTMLInputElement> but it doesn't have 'data' on it.
        onBeforeInputCapture={(e: any) => {
          if (
            e.data.includes('\n') &&
            inputRef.current &&
            inputRef.current.selectionStart !== null
          ) {
            e.preventDefault();
            const followingLine = inputRef.current.selectionStart > 0;
            addTask(followingLine);
          }
        }}
        onChange={(e) => {
          updateText(e.target.value);
        }}
        onKeyDown={onKeyDown}
        inputRef={inputRef}
        InputProps={{ disableUnderline: true }}
        style={{ flex: 1 }}
        autoComplete="off"
      />
      <div
        style={{
          opacity: hovering || !!task.reminder ? 1 : 0,
          transition: 'opacity 0.2s',
        }}
      >
        <ReminderButton task={task} setReminder={setReminder} />
      </div>

      <IconButton aria-label="delete" onClick={() => deleteTask(false)}>
        <ClearIcon
          style={{
            color: 'lightgray',
            opacity: hovering ? 1 : 0,
            transition: 'opacity 0.2s',
          }}
        />
      </IconButton>
    </div>
  );
}

export default TaskRow;
